import React, { useCallback, useState } from 'react';
import { Row, Col, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCoffeeInfo,
  // getAllCoffeeShop,
  getNLocal,
  saveCoffeeBook,
  updateCoffeeInfo,
  deleteCoffeeInfo,
} from 'modules/coffee';
import NLocalCard from './component/NLocalCard';
import DataGgList from './datagg/DataGgList';
import { getRemoveTag } from 'utils/CommonUtil';

function CoffeeScreen() {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [selectedOid, setSelectedOid] = useState('');

  const shoplist = useSelector(
    (state) => state.coffee.naver.data && state.coffee.naver.data.data
  );

  const infoStaus = useSelector((state) => state.coffee.info);

  const infoObj = useSelector((state) => state.coffee.info.data);

  const delcoffeeinfo = useSelector((state) => state.coffee.delcoffeeinfo);

  const handleGetDataGG = useCallback(
    (item) => {
      console.log('[sleected shop]', item);
      let shopNm = getRemoveTag(item.name);
      let sigunNm = item.sigunNm;
      let keyword = `${shopNm} ${sigunNm}`;
      let key = item.key;
      setSelectedOid(key);
      dispatch(getNLocal(keyword));
      api.info({
        message: '선택 커피숍명',
        description: `${shopNm} 명으로  조회되었습니다.`,
        placement: 'bottomRight',
      });
    },
    [dispatch, api]
  );

  const handleRegisterCafe = useCallback(
    (item) => {
      console.log('[등록 대상]', item);
      const title = getRemoveTag(item.title);

      try {
        const so = { ...item, previewImages: [], images: [] };
        dispatch(saveCoffeeBook(so));

        console.log('[key is]', selectedOid);
        let vo = {
          sigunCd: '41500',
          oid: selectedOid,
          columnNm: 'status',
          value: 'R',
        };
        dispatch(updateCoffeeInfo(vo));
        dispatch(getAllCoffeeInfo());
        api.info({
          message: '등록되었습니다.',
          description: `${title} 카페가 정상등록되었습니다.`,
          placement: 'bottomRight',
        });
      } catch (e) {
        api.error({
          message: '등록이 실패하였습니다.',
          description: e.toString(),
          placement: 'bottomRight',
        });
      }
    },
    [dispatch, selectedOid, api]
  );

  const handleGet = useCallback(() => {
    dispatch(getAllCoffeeInfo());
  }, [dispatch]);

  const handleDeleteShop = useCallback(
    (item) => {
      console.log('[삭제 item]', item);
      let id = item.key;
      const title = item.name;
      dispatch(deleteCoffeeInfo(id));

      console.log('[CHECK] ', delcoffeeinfo.data !== null, delcoffeeinfo.data);
      if (delcoffeeinfo.data !== null) {
        handleGet();
        api.info({
          message: '삭제완료',
          description: `${title} 삭제가 정상 처리되었습니다.`,
          placement: 'bottomRight',
        });
      }
    },
    [dispatch, api, delcoffeeinfo, handleGet]
  );

  const makeList = useCallback((datas) => {
    if (datas === null) return [];
    const mdata = [...datas];
    let mlist = mdata.map((item) => ({
      key: item['id'],
      name: item.get('BIZPLC_NM'),
      address: item.get('REFINE_ROADNM_ADDR'),
      mapx: item.get('REFINE_WGS84_LOGT'),
      mapy: item.get('REFINE_WGS84_LAT'),
      sigunNm: item.get('SIGUN_NM'),
    }));
    return mlist;
  }, []);

  // useEffect(() => {
  //   handleGet();
  // }, [handleGet]);

  return (
    <>
      {contextHolder}
      <div>Coffee Home ({infoObj && infoObj.length}) </div>
      <Row>
        <Col>
          <DataGgList
            onClickShop={handleGetDataGG}
            handleGet={handleGet}
            handleDeleteShop={handleDeleteShop}
            dataSource={makeList(infoStaus.data)}
            loading={infoStaus.loading ?? false}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {shoplist &&
            shoplist.map((item) => (
              <NLocalCard
                key={`${item.mapx}_${item.mapy}`}
                item={item}
                onRegister={() => handleRegisterCafe(item)}
              />
            ))}
        </Col>
      </Row>
    </>
  );
}

export default CoffeeScreen;
