function FoodCard({ data }) {
  return (
    <ul>
      {data &&
        data.map((item) => {
          return <li key={item.get('objectId')}>{item.get('title')}</li>;
        })}
    </ul>
  );
}

export default FoodCard;
