import Home from 'screen/Home';
import './css/styles.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import About from 'screen/About';
import Contact from 'screen/Contact';
import Service from 'screen/Service';
import Blog from 'screen/Blog';
import MemberHome from 'screen/member/MemberHome';
import OauthService from 'screen/Login/OauthService';
import MemberProduct from 'screen/member/MemberProduct';
import Privacy from 'screen/Privacy';
import FoodEverline from 'screen/apps/FoodEverline';
import MemberApp from 'screen/MemberApp';

import OAuthLogin from 'screens/login/OAuthLogin';
import ErrorPage from 'components/EroorPage';
import NotFoundPage from 'NotFoundPage';

function App() {
  return (
    <div>
      <Routes>
        <Route exact path='/' element={<Home />} />

        <Route
          path={'/login'}
          element={<OAuthLogin />}
          errorElement={<ErrorPage />}
        />

        <Route path={'/about'} element={<About />} />
        <Route path={'/contact'} element={<Contact />} />
        <Route path={'/services'} element={<Service />} />
        <Route path={'/blog'} element={<Blog />} />
        <Route path={'/oauth'} element={<OauthService />} />
        <Route path={'/member_home'} element={<MemberHome />} />
        <Route path={'/member_product'} element={<MemberProduct />} />
        <Route path={'/privacy'} element={<Privacy />} />
        <Route path={'/food_everline'} element={<FoodEverline />} />
        <Route path={'/member/*'} element={<MemberApp />} />

        <Route path='/404' element={<NotFoundPage />} />

        <Route path='*' element={<Navigate to='/404' replace />} />
      </Routes>
    </div>
  );
}

export default App;
