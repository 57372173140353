import Parse from 'parse';
import { changeStringToDouble, getRemoveTag } from 'utils/CommonUtil';
import Axios from 'axios';

const clNmSidoMaster = 'SidoMaster';
const clNmSidoDetail = 'SidoDetail';

export const deleteDocById = async (id) => {
  // ParseSetting.initViet();
  const po = new Parse.Object('CoffeeInfo_41500');
  po.set('objectId', id);

  return await po.destroy();
};

export const saveCoffeeMaster = async (item) => {
  let shopNm = getRemoveTag(item['title']);
  let sigunCd = '41500';

  let geo = new Parse.GeoPoint(
    changeStringToDouble(item.mapy),
    changeStringToDouble(item.mapx)
  );
  const po = new Parse.Object('CoffeeBook_41500');
  po.set('shopNm', shopNm);
  po.set('sigunCd', sigunCd);
  po.set('location', geo);
  po.set('data', item);

  return await po.save();
};

export const updateCoffeeInfo = async (item) => {
  // ParseSetting.initViet();
  console.log('[CHECK >>> ]', item);
  let sigunCd = item.sigunCd;
  let oid = item.oid;
  let columnNm = item.columnNm;
  let value = item.value;
  let className = `CoffeeBook_${sigunCd}`;
  const uo = new Parse.Object(className);
  uo.set('objectId', oid);
  uo.set(columnNm, value);

  return await uo.save();
};

export const getNaverImage = async (keyword) => {
  const enkeyword = encodeURI(keyword);
  const url = `/api/naver_image/${enkeyword}`;
  return await Axios.get(url);
};

export const getSidoAll = async () => {
  const res = await Axios.get(
    'https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=*00000000'
  );
  return res;
};

export const saveSidoAll = async (list) => {
  // ParseSetting.initViet();

  let arr = [];

  for (let i = 0; i < list.length; i++) {
    let po = new Parse.Object(clNmSidoMaster);

    po.set('code', list[i]['code']);
    po.set('name', list[i]['name']);
    arr.push(po);
  }

  //   console.log('[In saveSidoAll]', arr);
  await Parse.Object.saveAll(arr);
  //   console.log('[res is]', res);
};

export const getBySidoCode = async (item) => {
  const code = item.code;
  let keyCode = '';
  if (code.length > 3) {
    keyCode = `${code.substr(0, 2)}*000000`;
  }

  const res = await Axios.get(
    `https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=${keyCode}`
  );
  return res;
};

export const saveDetailSidoAll = async ({ item, list }) => {
  // ParseSetting.initViet();

  const upperName = item?.name;
  const upperCode = item?.code;

  const filteredList = list.filter((ele) => ele.code !== item.code);

  const targetList = filteredList.map((ele) => {
    const nm = ele.name;
    const city = nm.replace(upperName, '');
    const obj = {
      code: ele.code,
      name: city,
    };
    return obj;
  });

  // console.log('[item] ', item, targetList);

  let arr = [];
  for (let i = 0; i < targetList.length; i++) {
    let po = new Parse.Object(clNmSidoDetail);

    po.set('code', targetList[i]['code']);
    po.set('name', targetList[i]['name']);
    po.set('upperName', upperName);
    po.set('upperCode', upperCode);

    arr.push(po);
  }

  // console.log('[In saveDetailSidoAll]', arr);
  await Parse.Object.saveAll(arr);
};

export const getSidoDetailAll = async () => {
  // ParseSetting.initViet();

  const query = new Parse.Query(clNmSidoDetail);

  const res = await query.findAll();

  const arr = [];
  res.map((item) => {
    const nm = item.get('upperName');
    const cd = item.get('upperCode');
    if (arr.findIndex((ele) => ele.name === nm) === -1) {
      const obj = { name: nm, code: cd };
      arr.push(obj);
    }
    return item;
  });

  return arr;
};

export const getAllFoodShop = async () => {
  // ParseSetting.initViet();
  const query = new Parse.Query('RestaurantMaster');
  return await query.find();
};
