import { getAllManualShopAsync } from 'apis/ShopApi';
import ManualShop from 'components/ManualShop';

function MnShopHandmadeApp() {
  return (
    <>
      <ManualShop
        getAllManualShopAsync={getAllManualShopAsync}
        appName={'HandmadeApp'}
      />
    </>
  );
}

export default MnShopHandmadeApp;
