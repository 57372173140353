import React, { useRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
// import { debounce } from 'lodash';

function KakaoMap({ lat = 33.450701, lng = 126.570667 }) {
  const [kmap, setKmap] = useState(null);
  const kakaoMap = window.kakao;
  const mapRef = useRef({});
  const handleMap = useCallback(() => {
    // const rand = new Date();
    //mapRef.current = '2';

    const container = mapRef.current;
    // const container = mapRef.current;
    if (container && kakaoMap) {
      // console.log('[C,K]', container, kakaoMap);
      const options = {
        center: new kakaoMap.maps.LatLng(lat, lng),
        level: 3,
      };

      const m = new kakaoMap.maps.Map(container, options);

      // 마커가 표시될 위치입니다
      const markerPosition = new kakaoMap.maps.LatLng(33.450701, 126.570667);

      // 마커를 생성합니다
      const marker = new kakaoMap.maps.Marker({
        position: markerPosition,
      });

      // 마커가 지도 위에 표시되도록 설정합니다
      marker.setMap(m);
      //
      setKmap(m);
    }
    return null;
  }, [kakaoMap, lat, lng]);

  useEffect(() => {
    handleMap();
    if (kmap) {
      const bounds = new kakaoMap.maps.LatLngBounds();
      bounds.extend(new kakaoMap.maps.LatLng(lat, lng));
      // console.log('[kmap is]', bounds, kmap);
      kmap.setBounds(bounds);
    }

    return () => {
      mapRef.current = null;
    };
  }, [handleMap, kakaoMap, kmap, lat, lng]);

  return <MapDiv ref={mapRef} />;
}

export default KakaoMap;

const MapDiv = styled.div`
  width: 500px;
  height: 400px;
`;
