import { useMutation, useQuery } from '@tanstack/react-query';
import { Row, Col, Button, notification, Table, Space, Card } from 'antd';
import {
  getBySidoCode,
  getSidoAll,
  getSidoDetailAll,
  saveDetailSidoAll,
  saveSidoAll,
} from 'apis/OleumApi';
import React, { useCallback, useMemo, useState } from 'react';

function OleumScreen() {
  const [api, contextHolder] = notification.useNotification();

  const [callSido, setCallSido] = useState(false);
  const [sidoItem, setSidoItem] = useState({});

  const { data } = useQuery({
    queryKey: ['sidos'],
    queryFn: getSidoAll,
  });

  const {
    data: sidoList,
    isLoading,
    refetch: refetchSido,
  } = useQuery({
    queryKey: ['sidoList'],
    queryFn: () => getBySidoCode(sidoItem),
    enabled: callSido,
  });

  const {
    data: sidoDetailList,
    isLoading: loadingDetail,
    refetch: refetchDetail,
  } = useQuery({
    queryKey: ['sidoDetailList'],
    queryFn: getSidoDetailAll,
    enabled: callSido,
  });
  //   console.log('[sidoList] ', sidoList);

  const sidos = useMemo(() => {
    if (Array.isArray(data?.data?.regcodes)) {
      return data?.data?.regcodes;
    }
    return [];
  }, [data]);

  const { mutate: saveSidoMutate } = useMutation({
    mutationFn: saveSidoAll,
    onSuccess: () => {
      api.success({ message: 'success', description: '정상 처리되었습니다.' });
    },
    onError: (err) => {
      api.error({ message: 'error', description: err.toString() });
    },
  });

  const { mutate: saveDetailMutate } = useMutation({
    mutationFn: saveDetailSidoAll,
    onSuccess: () => {
      api.success({ message: 'success', description: '정상 처리되었습니다.' });
      refetchSido();
      refetchDetail();
    },
    onError: (err) => {
      api.error({ message: 'error', description: err.toString() });
    },
  });

  const handleSidoSave = useCallback(() => {
    saveSidoMutate(sidos);
  }, [saveSidoMutate, sidos]);

  const handleSidoGet = useCallback((item) => {
    setCallSido(true);
    setSidoItem(item);
  }, []);

  const disable = useMemo(() => {
    const isExist =
      sidoDetailList &&
      sidoDetailList.findIndex((ele) => ele.code === sidoItem.code) > -1;

    // console.log('[check]', isExist, sidoDetailList);
    return isExist;
  }, [sidoItem, sidoDetailList]);

  const columns = useMemo(() => {
    return [
      { title: 'CODE', dataIndex: 'code' },
      { title: 'Name', dataIndex: 'name' },
    ];
  }, []);

  const dataSource = useMemo(() => {
    if (sidoList && Array.isArray(sidoList?.data?.regcodes)) {
      setCallSido(false);
      return sidoList.data.regcodes;
    }
    return [];
  }, [sidoList]);

  const handleDetailSido = useCallback(() => {
    // console.log('[handleDetailSido]', sidoItem, dataSource);
    saveDetailMutate({ item: sidoItem, list: dataSource });
  }, [saveDetailMutate, sidoItem, dataSource]);

  const columnsDetail = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
      },
    ];
  }, []);

  return (
    <>
      {contextHolder}
      <Row>
        <Col span={24}>
          <Card>
            <Button onClick={handleSidoSave} disabled>
              Save
            </Button>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Card>Oleum Screen ({data?.data?.regcodes?.length})</Card>
          <Card>
            <ul>
              {sidos?.map((item, idx) => (
                <li key={item.code}>
                  <Button onClick={() => handleSidoGet(item)}>
                    {idx + 1}. {item.name}
                  </Button>
                </li>
              ))}
            </ul>
          </Card>
        </Col>
        <Col span={8}>
          <Space direction='vertical' size={'middle'}>
            <Card>
              <Button
                onClick={handleDetailSido}
                type='primary'
                disabled={disable}
              >
                Save {sidoItem.name}
              </Button>
            </Card>
            <Card>
              <Table
                rowKey={'code'}
                columns={columns}
                dataSource={dataSource}
                loading={isLoading}
              />
            </Card>
          </Space>
        </Col>
        <Col>
          <Card span={6}>
            <Table
              rowKey={'name'}
              loading={loadingDetail}
              columns={columnsDetail}
              dataSource={sidoDetailList}
              pagination={{ pageSize: 50 }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default OleumScreen;
