import { Card, Image, Table, Tabs } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { getKakaoLocal } from 'apis/ShopApi';
import { useQuery } from '@tanstack/react-query';
import ShopCard from './shop_component/ShopCard';
import KakaoPlace from './KakaoPlace';

function ManualShopCard({ shop = {}, item = {}, managers = [], open = false }) {
  const NO_IMAGE = process.env.REACT_APP_NO_IMAGE_BASE64;

  const cityNm = sessionStorage.getItem('cityNm');

  const { data } = useQuery({
    queryKey: ['kakaoSearch'],
    queryFn: () => getKakaoLocal({ keyword: shop?.b_name, cityNm: cityNm }),
    enabled: open,
  });

  const columns = useMemo(() => {
    return [
      { title: '사업자 등록번호', dataIndex: 'b_no' },
      { title: '대표', dataIndex: 'p_nm' },
      { title: '납세형태', dataIndex: 'b_stt' },
      { title: '과세유형', dataIndex: 'tax_type' },
    ];
  }, []);

  const dataSource = useMemo(() => {
    // console.log('[item]', item);
    return item
      ? [
          {
            key: item?.['b_no'],
            b_no: item?.['b_no'],
            p_nm: item?.['p_nm'],
            b_stt: item?.['b_stt'],
            tax_type: item?.['tax_type'],
          },
        ]
      : [];
  }, [item]);

  const managerInfo = useMemo(() => {
    // console.log('[len]', managers.length);
    return Array.isArray(managers)
      ? managers.map((item) => {
          // console.log('[manager]', item);
          return (
            <div key={item}>
              {item?.get('nickName')} [{item?.get('oauthType')}]{' '}
              <Image
                key={item}
                width={120}
                src={item?.get('profileImage')}
                fallback={NO_IMAGE}
              />
            </div>
          );
        })
      : 'None';
  }, [NO_IMAGE, managers]);

  // console.log('[shop is]', shop);

  const items = useMemo(() => {
    const arrs = [
      {
        key: 'shopcard',
        label: '정보',
        children: <ShopCard shop={shop} data={data} />,
      },
      {
        key: 'managers',
        label: '매니저',
        children: (
          <Card title='정보관리 매니저' key='managerInfo'>
            <strong>{managerInfo}</strong>
          </Card>
        ),
      },
      {
        key: 'taxinfo',
        label: '세금정보',
        children: (
          <Card title='세금정보' key={'taxInfo'}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </Card>
        ),
      },
    ];

    if (shop?.['is_set_portal'] === true) {
      // console.log('[>>]', shop);
      arrs.push({
        key: 'kakaoplace',
        label: 'KAKAO 정보',
        children: (
          <Card>
            <KakaoPlace id={shop?.['kakaoId']} />
          </Card>
        ),
      });
    }

    return arrs;
  }, [columns, data, dataSource, managerInfo, shop]);

  const handleTabChange = useCallback((key) => {
    console.log('[Tab key]', key);
  }, []);

  return item ? (
    <Tabs
      defaultActiveKey='shopcard'
      items={items}
      onChange={handleTabChange}
    />
  ) : (
    <></>
  );
}

export default ManualShopCard;
