import { Button, Card, Col, Flex, Row, Input } from 'antd';
import { validateCompanyAPI } from 'apis/ShopApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TaxInfo from './TaxInfo';

function MngShopCreate({ refetch, setVisible }) {
  const initObj = useMemo(() => {
    return {
      bno: '',
      startDt: '',
      name: '',
      shopNm: '',
    };
  }, []);
  const [param, setParam] = useState(initObj);
  const [data, setData] = useState({});
  const handleGet = useCallback(async () => {
    const res = await validateCompanyAPI(param);

    if (res.status === 200) {
      setData(res);
    }
  }, [param]);

  const taxInfo = useMemo(() => {
    if (data) {
      return data;
    }
  }, [data]);

  const handleInput = useCallback(
    (keyName, e) => {
      const value = e.target.value;

      const trimValue = value?.trim();
      let replaceStr = trimValue.replace(/-/g, '');
      const obj = {
        ...param,
        [keyName]: replaceStr,
      };

      setParam(obj);
    },
    [param]
  );

  useEffect(() => {
    setParam(initObj);
    setData({});
  }, [initObj]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Card>
            <Row>
              <Input
                onChange={(e) => handleInput('shopNm', e)}
                value={param['shopNm']}
                placeholder='업체명을 입력하세요.'
              />
            </Row>
            <Row>
              <Input
                onChange={(e) => handleInput('bno', e)}
                value={param['bno']}
                placeholder='사업자 등록번호를 입력하세요. (-없이)'
              />
            </Row>
            <Row>
              <Input
                onChange={(e) => handleInput('name', e)}
                value={param['name']}
                placeholder='대표자명을 입력하세요.'
              />
            </Row>
            <Row>
              <Input
                onChange={(e) => handleInput('startDt', e)}
                value={param['startDt']}
                placeholder='창립일을 입력하세요.(yyymmdd형태)'
              />
            </Row>

            <Flex wrap justify='flex-end'>
              <Row>
                <Button onClick={handleGet}>Find</Button>
              </Row>
            </Flex>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TaxInfo
            data={taxInfo}
            refetch={refetch}
            setVisible={setVisible}
            setParam={setParam}
          />
        </Col>
      </Row>
    </>
  );
}

export default MngShopCreate;
