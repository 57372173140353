import { getRemoveTag } from 'utils/CommonUtil';

const { Row, Col, Card, Button } = require('antd');

function NLocalCard({ item, onRegister }) {
  return (
    <Row>
      <Col>
        <Card title={getRemoveTag(item.title)}>
          <Col>{item.category}</Col>
          <Col>{item.roadAddress}</Col>
          <Col>{item.mapx}</Col>
          <Col>{item.mapy}</Col>
          <Col>
            <Button onClick={() => onRegister(item)}>등록</Button>
          </Col>
        </Card>
      </Col>
    </Row>
  );
}

export default NLocalCard;
