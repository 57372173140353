import * as coffeeAPI from 'apis/CoffeeApi';
import * as DataGgAPI from 'apis/DataGgApi';
import { takeEvery } from 'redux-saga/effects';
import {
  createPromiseSaga,
  handleAsyncActions,
  reducerUtils,
} from 'utils/asyncUtils';

// 액션 타입
const GET_ALL_COFFEE = 'COFFEE/GET_ALL_COFFEE';
const GET_ALL_COFFEE_SUCCESS = 'COFFEE/GET_ALL_COFFEE_SUCCESS';
const GET_ALL_COFFEE_ERROR = 'COFFEE/GET_ALL_COFFEE_ERROR';

const GET_NLOCAL = 'COFFEE/GET_NLOCAL';
const GET_NLOCAL_SUCCESS = 'COFFEE/GET_NLOCAL_SUCCESS';
const GET_NLOCAL_ERROR = 'COFFEE/GET_NLOCAL_ERROR';

const GET_ALL_COFFEE_INFO = 'COFFEE/GET_ALL_COFFEE_INFO';
const GET_ALL_COFFEE_INFO_SUCCESS = 'COFFEE/GET_ALL_COFFEE_INFO_SUCCESS';
const GET_ALL_COFFEE_INFO_ERROR = 'COFFEE/GET_ALL_COFFEE_INFO_ERROR';

const DELETE_ALL_COFFEE_INFO = 'COFFEE/DELETE_ALL_COFFEE_INFO';
const DELETE_ALL_COFFEE_INFO_SUCCESS = 'COFFEE/DELETE_ALL_COFFEE_INFO_SUCCESS';
const DELETE_ALL_COFFEE_INFO_ERROR = 'COFFEE/DELETE_ALL_COFFEE_INFO_ERROR';

const SAVE_COFFEE_BOOK = 'COFFEE/SAVE_COFFEE_BOOK';
const SAVE_COFFEE_BOOK_SUCCESS = 'COFFEE/SAVE_COFFEE_BOOK_SUCCESS';
const SAVE_COFFEE_BOOK_ERROR = 'COFFEE/SAVE_COFFEE_BOOK_ERROR';

const UPDATE_COFFEE_INFO = 'COFFEE/UPDATE_COFFEE_INFO';
const UPDATE_COFFEE_INFO_SUCCESS = 'COFFEE/UPDATE_COFFEE_INFO_SUCCESS';
const UPDATE_COFFEE_INFO_ERROR = 'COFFEE/UPDATE_COFFEE_INFO_ERROR';

const GET_NAVER_IMAGE = 'COFFEE/GET_NAVER_IMAGE';
const GET_NAVER_IMAGE_SUCCESS = 'COFFEE/GET_NAVER_IMAGE_SUCCESS';
const GET_NAVER_IMAGE_ERROR = 'COFFEE/GET_NAVER_IMAGE_ERROR';

// 액션 생성 함수
export const getAllCoffeeShop = () => ({ type: GET_ALL_COFFEE });

const getAllCoffeeShopSaga = createPromiseSaga(
  GET_ALL_COFFEE,
  coffeeAPI.getAllCoffeeShop
);

export const getNLocal = (keyword) => ({
  type: GET_NLOCAL,
  payload: keyword,
  meta: keyword,
});

const getNLocalSaga = createPromiseSaga(GET_NLOCAL, DataGgAPI.getNLocal);

export const getAllCoffeeInfo = () => ({ type: GET_ALL_COFFEE_INFO });

const getAllCoffeeInfoSaga = createPromiseSaga(
  GET_ALL_COFFEE_INFO,
  coffeeAPI.getAllCoffeeInfo
);

export const deleteCoffeeInfo = (id) => ({
  type: DELETE_ALL_COFFEE_INFO,
  payload: id,
  meta: id,
});

const deleteCoffeeInfoSaga = createPromiseSaga(
  DELETE_ALL_COFFEE_INFO,
  coffeeAPI.deleteDocById
);

export const saveCoffeeBook = (item) => ({
  type: SAVE_COFFEE_BOOK,
  payload: item,
  meta: item,
});

const saveCoffeeBookSaga = createPromiseSaga(
  SAVE_COFFEE_BOOK,
  coffeeAPI.saveCoffeeMaster
);

export const updateCoffeeInfo = (item) => ({
  type: UPDATE_COFFEE_INFO,
  payload: item,
  meta: item,
});

const updateCoffeeInfoSaga = createPromiseSaga(
  UPDATE_COFFEE_INFO,
  coffeeAPI.updateCoffeeInfo
);

const getNaverImageSaga = createPromiseSaga(
  GET_NAVER_IMAGE,
  coffeeAPI.getNaverImage
);

export const getNaverImage = (keyword) => ({
  type: GET_NAVER_IMAGE,
  payload: keyword,
  meta: keyword,
});

// WARP SAGA
export function* coffeeSaga() {
  yield takeEvery(GET_ALL_COFFEE, getAllCoffeeShopSaga);
  yield takeEvery(GET_NLOCAL, getNLocalSaga);
  yield takeEvery(GET_ALL_COFFEE_INFO, getAllCoffeeInfoSaga);
  yield takeEvery(DELETE_ALL_COFFEE_INFO, deleteCoffeeInfoSaga);
  yield takeEvery(SAVE_COFFEE_BOOK, saveCoffeeBookSaga);
  yield takeEvery(UPDATE_COFFEE_INFO, updateCoffeeInfoSaga);
  yield takeEvery(GET_NAVER_IMAGE, getNaverImageSaga);
}

// 초기값
const initialState = {
  shops: reducerUtils.initial(),
  naver: reducerUtils.initial(),
  info: reducerUtils.initial(),
  delcoffeeinfo: reducerUtils.initial(),
  savebook: reducerUtils.initial(),
  upinfo: reducerUtils.initial(),
  images: reducerUtils.initial(),
};

export default function coffee(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COFFEE:
    case GET_ALL_COFFEE_SUCCESS:
    case GET_ALL_COFFEE_ERROR:
      return handleAsyncActions(GET_ALL_COFFEE, 'shops', true)(state, action);
    case GET_NLOCAL:
    case GET_NLOCAL_SUCCESS:
    case GET_NLOCAL_ERROR:
      return handleAsyncActions(GET_NLOCAL, 'naver', true)(state, action);
    case GET_ALL_COFFEE_INFO:
    case GET_ALL_COFFEE_INFO_SUCCESS:
    case GET_ALL_COFFEE_INFO_ERROR:
      return handleAsyncActions(
        GET_ALL_COFFEE_INFO,
        'info',
        true
      )(state, action);
    case DELETE_ALL_COFFEE_INFO:
    case DELETE_ALL_COFFEE_INFO_SUCCESS:
    case DELETE_ALL_COFFEE_INFO_ERROR:
      return handleAsyncActions(
        DELETE_ALL_COFFEE_INFO,
        'delcoffeeinfo',
        true
      )(state, action);
    case SAVE_COFFEE_BOOK:
    case SAVE_COFFEE_BOOK_SUCCESS:
    case SAVE_COFFEE_BOOK_ERROR:
      return handleAsyncActions(
        SAVE_COFFEE_BOOK,
        'savebook',
        true
      )(state, action);
    case UPDATE_COFFEE_INFO:
    case UPDATE_COFFEE_INFO_SUCCESS:
    case UPDATE_COFFEE_INFO_ERROR:
      return handleAsyncActions(
        UPDATE_COFFEE_INFO,
        'upinfo',
        true
      )(state, action);
    case GET_NAVER_IMAGE:
    case GET_NAVER_IMAGE_SUCCESS:
    case GET_NAVER_IMAGE_ERROR:
      return handleAsyncActions(GET_NAVER_IMAGE, 'images', true)(state, action);
    default:
      return state;
  }
}
