import Parse from 'parse';

class ParseSetting {
  static initFood() {
    const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APP_ID_FOOD;
    const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JS_KEY_FOOD;

    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = 'https://parseapi.back4app.com';
  }

  static initCoffee() {
    const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APP_ID_COFF;
    const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JS_KEY_COFF;

    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = 'https://parseapi.back4app.com';
  }

  static initMind() {
    const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APP_ID_MIND;
    const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JS_KEY_MIND;

    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = 'https://parseapi.back4app.com';
  }

  static initViet() {
    const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APP_ID_VIET;
    const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JS_KEY_VIET;

    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = 'https://parseapi.back4app.com';
  }

  static initOleum() {
    const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APP_ID_OLEU;
    const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JS_KEY_OLEU;

    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = 'https://parseapi.back4app.com';
  }

  static initReservation() {
    const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APP_ID_RESE;
    const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JS_KEY_RESE;

    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = 'https://parseapi.back4app.com';
  }

  static initHandmadeApp() {
    const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APP_ID_HAND;
    const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JS_KEY_HAND;

    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = 'https://parseapi.back4app.com';
  }

  static initHC4U() {
    const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APP_ID_HC4U;
    const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JS_KEY_HC4U;

    Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
    Parse.serverURL = 'https://parseapi.back4app.com';
  }
}

export default ParseSetting;
