import React, { useState, useMemo, useCallback } from 'react';
import {
  Layout,
  theme,
  notification,
  Col,
  Row,
  Breadcrumb,
  Button,
} from 'antd';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';

import HaMenu from 'components/HaMenu';
import styled from 'styled-components';
import Parse from 'parse';

import FoodScreen from 'screens/food/FoodScreen';
import HomeScreen from 'screens/HomeScreen';
import MindScreen from 'screens/mind/MindScreen';
import VietScreen from 'screens/viet/VietScreen';
import CoffeeScreen from 'screens/coffee/CoffeeScreen';
import CoffeeData from 'screens/coffee/CoffeeData';
import OleumScreen from 'screens/oleum/OleumScreen';
import MindSido from 'screens/mind/MindSido';
import FoodSido from 'screens/food/FoodSido';
import CoffeeSido from 'screens/coffee/CoffeeSido';
import VietSido from 'screens/viet/VietSido';
import ReseSido from 'screens/rese/ReseSido';
import HaSido from 'screens/handmadeapp/HaSido';
import MnShopHandmadeApp from 'screens/handmadeapp/MnShopHandmadeApp';
import MemberCard from 'components/member/MemberCard';
import SimpleModal from 'components/SimpleModal';
import MenuMng from 'components/admin/MenuMng';

const Context = React.createContext({ name: 'handmade app management' });

const { Header, Content, Footer, Sider } = Layout;

const MemberApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [api, contextHolder] = notification.useNotification();
  const [user, setUser] = useState(null);
  const [visibleSm, setVisibleSm] = useState(false);

  let confirmObj = useMemo(() => {
    return {
      title: '',
      content: '',
      confirm: () => {},
    };
  }, []);

  const [collapsed, setCollapsed] = useState(false);
  const {
    // eslint-disable-next-line no-unused-vars
    token: { colorBgContainer },
  } = theme.useToken();

  const contextValue = useMemo(
    () => ({
      name: 'Coffee Gather',
    }),
    []
  );

  const appName = useMemo(() => {
    const appKey = sessionStorage.getItem('appKey');
    // console.log('[appName]', appKey);
    switch (appKey) {
      case 'hand':
        return '핸드메이드앱';
      case 'food':
        return '음식탐험 에버라인';
      case 'mind':
        return '마인드';
      case 'coff':
        return '우리동네 커피';
      case 'viet':
        return '비엣통';
      case 'rese':
        return '예약통';
      case 'oleu':
        return '제주오름';
      case 'hc4u':
        return '현대교회';
      default:
        return '';
    }
  }, []);

  const breads = useMemo(() => {
    const pathname = location?.pathname;
    const arrs = [
      { label: 'HandmadeApp > 시도 데이터', path: '/ha_sido' },
      { label: 'HandmadeApp > 업체수동입력', path: '/manual_shop' },
    ];
    const pathName = location?.pathname;
    console.log(pathName);

    const filteredArr = arrs.filter((item) => item.path === pathname);
    if (filteredArr.length > 0) {
      const label = filteredArr[0]?.label;
      return [{ title: `Home` }, { title: label }];
    } else {
      return [
        { title: `[${appName}] Home` },
        { title: pathName.replaceAll('/', ' > ') },
      ];
    }
  }, [appName, location]);

  const loginCheck = useCallback(async () => {
    // ParseSetting.initHandmadeApp();
    try {
      const currentUser = await Parse.User.current();
      const isLogined = currentUser !== null;

      if (isLogined) {
        // console.log('[currentUser]', currentUser);
        setUser(currentUser);
      }
      return isLogined;
    } catch (e) {}
  }, []);

  const handleLogout = useCallback(() => {
    confirmObj.title = 'Logout';
    confirmObj.content = '로그아웃 하시겠습니까?';
    confirmObj.confirm = async () => {
      console.log('[logout]', window.Kakao);
      await Parse.User.logOut();

      api.info({ message: '로그아웃 되었습니다.' });
      setVisibleSm(false);
      navigate('/login');
    };
    setVisibleSm(true);
  }, [api, confirmObj, navigate]);

  useMemo(async () => {
    const check = await loginCheck();
    console.log('[CHECK]', check);

    if (check === undefined) {
      navigate('/');
    }
    if (!check) {
      navigate('/login');
    }
  }, [loginCheck, navigate]);

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <div className='demo-logo-vertical' />
            <HaMenu />
          </Sider>
          <Layout>
            <Header
              style={{
                padding: 4,
              }}
            >
              <Row>
                <Col span={12} style={{ color: 'white', fontSize: 20 }}>
                  핸드메이드 앱 Web Manager{' '}
                  {user !== null ? (
                    <Button type='primary' onClick={handleLogout}>
                      Logout
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col span={4}>
                  <MemberCard user={user} />
                </Col>
                <Col span={8}>
                  <CustomBread separator={'>'} items={breads} />
                </Col>
              </Row>
            </Header>
            <Content
              style={{
                margin: '16px',
              }}
            >
              <Routes>
                <Route path='/*' element={<>멤버 앱 입니다.</>} />

                <Route path={'/home'} element={<HomeScreen />} />
                <Route path={'/oleum_screen'} element={<OleumScreen />} />

                <Route path={'/food_screen'} element={<FoodScreen />} />
                <Route path={'/food_sido'} element={<FoodSido />} />

                <Route path={'/mind_screen'} element={<MindScreen />} />
                <Route path={'/mind_sido'} element={<MindSido />} />

                <Route path={'/viet_screen'} element={<VietScreen />} />
                <Route path={'/viet_sido'} element={<VietSido />} />

                <Route path={'/coffee_screen'} element={<CoffeeScreen />} />
                <Route path={'/coffee_data'} element={<CoffeeData />} />
                <Route path={'/coffee_sido'} element={<CoffeeSido />} />

                <Route path={'/rese_sido'} element={<ReseSido />} />

                <Route path='/ha_sido' element={<HaSido />} />
                <Route path='/manual_shop' element={<MnShopHandmadeApp />} />
                <Route path='/admin_menu_mng' element={<MenuMng />} />
              </Routes>
            </Content>
            <Footer
              style={{
                textAlign: 'center',
              }}
            >
              Ant Design ©{new Date().getFullYear()} Created by Ant UED
            </Footer>
          </Layout>
        </Layout>
        <SimpleModal
          confirmObj={confirmObj}
          visible={visibleSm}
          setVisible={setVisibleSm}
        />
      </Context.Provider>
    </>
  );
};
export default MemberApp;

const CustomBread = styled(Breadcrumb)`
  .ant-breadcrumb {
    margin-top: 10px;
  }
  .ant-breadcrumb-link {
    color: white;
  }
  .ant-breadcrumb-separator {
    color: cyan;
  }
`;
