import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, Progress, notification, Image, Col } from 'antd';
import CoffeeCard from './component/CoffeeCard';
import {
  getAllCoffeeShop,
  getNaverImage,
  updateCoffeeInfo,
} from 'modules/coffee';

function CoffeeData() {
  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();
  const { data, loading, error } = useSelector((state) => state.coffee.shops);

  const { data: imgdata, loading: imgloading } = useSelector(
    (state) => state.coffee.images
  );

  const [selectedShop, setSelectedShop] = useState({});

  const handleGetALL = useCallback(() => {
    dispatch(getAllCoffeeShop());

    api.success({
      message: 'Success',
      description: '조회되었습니다.',
      placement: 'bottomRight',
    });
  }, [dispatch, api]);

  const handleGetImageByShopNm = useCallback(
    (keyword, record) => {
      setSelectedShop(record);
      let mkeyword = `${keyword}+이천커피`;
      console.log('[handleGetImageByShopNm] ', record);
      dispatch(getNaverImage(mkeyword));
      api.success({
        message: 'Success',
        description: `${keyword} 이미지 조회되었습니다.`,
        placement: 'bottomRight',
      });
    },
    [dispatch, api]
  );

  const applyImage = useCallback(
    (item) => {
      // console.log('[>>] ', selectedShop);
      if (selectedShop !== undefined) {
        let indata = selectedShop.get('data');
        let oid = selectedShop['id'];
        let url = item.thumbnail;
        const mdata = {
          ...indata,
          previewImages: [url],
          images: [url],
        };
        const param = {
          columnNm: 'data',
          value: mdata,
          sigunCd: '41500',
          oid: oid,
        };
        dispatch(updateCoffeeInfo(param));
        // console.log('[select image] ', selectedShop['id'], param);
        dispatch(getAllCoffeeShop());
      }
    },
    [dispatch, selectedShop]
  );

  return (
    <>
      {contextHolder}
      <Row>Coffee Data</Row>
      <Row gutter={24}>
        <Col span={12}>
          <Button onClick={handleGetALL}>Get</Button>
          {loading ? (
            <div>
              <Progress percent={100} type='dashboard' />
            </div>
          ) : (
            <CoffeeCard
              data={data}
              handleGetImageByShopNm={handleGetImageByShopNm}
            />
          )}

          {error ? <div>{error.toString()}</div> : ''}
        </Col>
        <Col span={12}>
          {imgloading ? (
            <Progress percent={100} type='dashboard' />
          ) : Array.isArray(imgdata && imgdata.data) ? (
            <ul>
              {imgdata.data.map((item) => {
                return (
                  <li key={item.thumbnail}>
                    <span>{item.title}</span>
                    <br />
                    <Image
                      width={200}
                      height={150}
                      src={item.thumbnail}
                      alt={item.title}
                    />
                    <br />
                    <Button onClick={() => applyImage(item)}>
                      이미지 적용하기
                    </Button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div>Select Shop</div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default CoffeeData;
