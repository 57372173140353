import { Menu } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { PieChartOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function HaMenu() {
  const navigate = useNavigate();
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const appSettingMenu = useCallback((arr) => {
    const appKey = sessionStorage.getItem('appKey');
    console.log('app key', appKey);
    switch (appKey) {
      case 'hand':
        // console.log('[hand]', appKey);
        arr.push(
          getItem('HandmadeApp', 'sub_ha', <UserOutlined />, [
            getItem('시도 데이터', '/member/ha_sido'),
            getItem('업체 수동입력', '/member/manual_shop'),
          ]),
          getItem('Admin', 'sub_ha', <UserOutlined />, [
            getItem('메뉴 관리', '/member/admin_menu_mng'),
          ])
        );
        return arr;
      case 'hc4u':
        // console.log('[hand]', appKey);
        arr.push(
          getItem('HC4U', 'sub_hc', <UserOutlined />, [
            getItem('시도 데이터', '/member/ha_sido'),
            getItem('업체 수동입력', '/member/manual_shop'),
          ]),
          getItem('Admin', 'sub_hc', <UserOutlined />, [
            getItem('메뉴 관리', '/member/admin_menu_mng'),
          ])
        );
        return arr;
      case 'food':
        arr.push(
          getItem('Food', 'sub_food', <UserOutlined />, [
            getItem('Food Home', '/member/food_screen'),
            getItem('시도 데이터', '/member/food_sido'),
          ])
        );
        return arr;
      case 'mind':
        arr.push(
          getItem('Mind', 'sub_mind', <UserOutlined />, [
            getItem('Mind Home', '/member/mind_screen'),
            getItem('시도 데이터', '/member/mind_sido'),
          ])
        );
        return arr;
      case 'coff':
        arr.push(
          getItem('Coffee', 'sub_coffee', <UserOutlined />, [
            getItem('Coffee Basic', '/member/coffee_screen'),
            getItem('Coffee Data', '/member/coffee_data'),
            getItem('시도 데이터', '/member/coffee_sido'),
          ])
        );
        return arr;
      case 'viet':
        arr.push(
          getItem('Viet', 'sub_viet', <UserOutlined />, [
            getItem('Viet Home', '/member/viet_screen'),
            getItem('시도 데이터', '/member/viet_sido'),
          ])
        );
        return arr;
      case 'rese':
        arr.push(
          getItem('Reservation', 'sub_rese', <UserOutlined />, [
            getItem('시도 데이터', '/member/rese_sido'),
          ])
        );
        return arr;
      case 'oleu':
        // console.log('[hanoleumd]', appKey);
        arr.push(
          getItem('Oleum', 'sub_oleum', <UserOutlined />, [
            getItem('Oleum Home', '/member/oleum_screen'),
          ])
        );
        return arr;
      default:
        arr.push();
        return arr;
    }
  }, []);

  const items = useMemo(() => {
    const arr = [getItem('Home', '/', <PieChartOutlined />)];
    const rtns = appSettingMenu(arr);
    console.log('[rtns]', rtns);
    return rtns;
  }, [appSettingMenu]);

  const handleMenuClick = useCallback(
    (item) => {
      let menuKey = item['key'];
      console.log('[menuKey]', menuKey);
      navigate(menuKey);
    },
    [navigate]
  );

  return (
    <Menu
      theme='dark'
      defaultSelectedKeys={['1']}
      mode='inline'
      items={items}
      onClick={handleMenuClick}
    />
  );
}

export default HaMenu;
