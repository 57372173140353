import React, { useCallback } from 'react';
import { Button, Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { getAllShop } from 'modules/food';
import FoodCard from './component/FoodCard';

function FoodScreen() {
  const { data, loading, error } = useSelector((state) => state.food.shops);
  const dispatch = useDispatch();

  const handleGetALL = useCallback(() => {
    dispatch(getAllShop());
  }, [dispatch]);

  // console.log('[loading] ', loading);

  return (
    <>
      <div>Food Home</div>

      <Button onClick={handleGetALL}>Get</Button>
      {loading ? (
        <div>
          <Progress percent={100} type='dashboard' />
        </div>
      ) : (
        <FoodCard data={data} />
      )}

      {error ? <div>{error.toString()}</div> : ''}
    </>
  );
}

export default FoodScreen;
