import { useCallback, useMemo, useState, useRef } from 'react';
import KakaoLogin from 'react-kakao-login';
import Parse from 'parse';
import ParseSetting from 'apis/ParseSetting';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Col, Row, Select, Space } from 'antd';
import img from 'images/bamboo.jpg';

function OAuthLogin() {
  const navigate = useNavigate();
  const kakaoRef = useRef();
  const [appKey, setAppKey] = useState('hc4u');

  const appSettingParse = useCallback(() => {
    sessionStorage.setItem('appKey', appKey);
    switch (appKey) {
      case 'hand':
        // console.log('[hand]', appKey);
        ParseSetting.initHandmadeApp();
        return;
      case 'food':
        // console.log('[food]', appKey);
        ParseSetting.initFood();
        return;
      case 'mind':
        // console.log('[mind]', appKey);
        ParseSetting.initMind();
        return;
      case 'coff':
        // console.log('[coff]', appKey);
        ParseSetting.initCoffee();
        return;
      case 'viet':
        // console.log('[viet]', appKey);
        ParseSetting.initViet();
        return;
      case 'rese':
        // console.log('[rese]', appKey);
        ParseSetting.initReservation();
        return;
      case 'oleu':
        // console.log('[hanoleumd]', appKey);
        ParseSetting.initOleum();
        return;
      case 'hc4u':
        ParseSetting.initHC4U();
        return;
      default:
        // console.log('[default]', appKey);
        ParseSetting.initHandmadeApp();
        return;
    }
  }, [appKey]);

  const parseLogin = useCallback(
    async (username, password) => {
      appSettingParse();

      await Parse.User.logIn(username, password);
      // console.log('[ParseLogin success]', loggedInUser);
      const currentUser = await Parse.User.current();
      if (currentUser !== null) {
        navigate('/member/home');
      } else {
        alert('Parse Login Error');
      }
    },
    [appSettingParse, navigate]
  );

  const kakaoOnSuccess = useCallback(
    async (data) => {
      // const idToken = data?.response?.access_token;
      // console.log('[BE 전달용 token] ', idToken);
      window.Kakao.API.request({
        url: '/v2/user/me',
      })
        .then(function (res) {
          const kuserId = `${res?.id}`;
          const nickname = res?.properties?.nickname;
          parseLogin(nickname, kuserId);
          console.log('[kakao user info]', kuserId, nickname, res);
        })
        .catch(function (err) {
          console.error(
            'failed to request user information: ' + JSON.stringify(err)
          );
        });
    },
    [parseLogin]
  );

  const kakaoOnFailure = useCallback((err) => {
    console.error('KAKAO LOGIN Fail', err);
  }, []);

  const kakaoClientId = useMemo(() => {
    const hand = process.env.REACT_APP_KAKAO_JSKEY_HAND;
    const hc4u = process.env.REACT_APP_KAKAO_JSKEY_HC4U;
    return hc4u;
    switch (appKey) {
      case 'hand':
        return hand;
      case 'food':
        return process.env.REACT_APP_KAKAO_JSKEY_FOOD;
      case 'mind':
        return process.env.REACT_APP_KAKAO_JSKEY_MIND;
      case 'coff':
        return process.env.REACT_APP_KAKAO_JSKEY_COFF;
      case 'viet':
        return process.env.REACT_APP_KAKAO_JSKEY_VIET;
      case 'rese':
        return process.env.REACT_APP_KAKAO_JSKEY_RESE;
      case 'oleu':
        return process.env.REACT_APP_KAKAO_JSKEY_OLEU;
      case 'hc4u':
        return hc4u;
      default:
        return hc4u;
    }
  }, [appKey]);

  const handleSelectChange = useCallback(
    (value) => {
      if (window.Kakao) {
        kakaoRef.current = window.Kakao;
        try {
          delete window.Kakao;

          if (window.Kakao === undefined) {
            window.Kakao = kakaoRef.current;
            console.log('[dynamic create]', kakaoClientId, window.Kakao);
            // window.Kakao.Auth.logout();
            console.log('[init]', kakaoClientId);
            // JavaScript key를 인자로 주고 SDK 초기화
            window.Kakao.init(kakaoClientId);
            // SDK 초기화 여부를 확인하자.
            // console.log('[USEFFECT CHECK THIS]', window.Kakao.isInitialized());
          }
        } catch (e) {
          //
        }
      }
      setAppKey(value);
    },
    [kakaoClientId]
  );

  const options = useMemo(() => {
    return [
      { value: 'hand', label: '핸드메이드앱' },
      { value: 'hc4u', label: '현대교회' },
      { value: 'food', label: '음식탐험 에버라인' },
      { value: 'mind', label: 'Mind' },
      { value: 'coff', label: '우리동네 커피' },
      { value: 'viet', label: '비엣통' },
      { value: 'rese', label: '예약통' },
      { value: 'oleu', label: '제주오름' },
    ];
  }, []);

  // useEffect(() => {
  //   // SDK는 한 번만 초기화해야 한다.
  //   // 중복되는 초기화를 막기 위해 isInitialized()로 SDK 초기화 여부를 판단한다.
  //   if (window.Kakao && !window.Kakao.isInitialized()) {
  //     console.log('[init]', kakaoClientId);
  //     // JavaScript key를 인자로 주고 SDK 초기화
  //     window.Kakao.init(kakaoClientId);
  //     // SDK 초기화 여부를 확인하자.
  //     // console.log('[USEFFECT CHECK THIS]', window.Kakao.isInitialized());
  //   }
  // }, [kakaoClientId]);

  return (
    <WrapDiv>
      <div className='login-head'>Welcome to the Handmade APP</div>
      <LoginCard title='앱 로그인'>
        <Space direction='vertical'>
          <Row>
            <Space>
              <Col>
                <label>앱 선택</label>
              </Col>
              <Col>
                <Select
                  style={{ width: 300 }}
                  onChange={handleSelectChange}
                  options={options}
                  value={appKey}
                />
              </Col>
            </Space>
          </Row>

          <Row>
            <KakaoLogin
              token={kakaoClientId}
              onSuccess={kakaoOnSuccess}
              onFail={kakaoOnFailure}
            />
            <Button className='home-btn' onClick={() => navigate('/')}>
              Home
            </Button>
          </Row>
        </Space>
      </LoginCard>
    </WrapDiv>
  );
}

export default OAuthLogin;

const WrapDiv = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${img});

  .login-head {
    font-size: 30px;
    position: absolute;
    top: 20vh;
    left: 10vh;
  }
`;

const LoginCard = styled(Card)`
  position: absolute;
  left: 10vh;
  top: 50vh;

  .home-btn {
    width: 120px;
    height: 50px;
  }
`;
